export const colors = {
    white: "",
    grey: "text-gray-700 bg-gray-200 hover:bg-gray-300 hover:text-gray-900 focus:ring-gray-200 active:bg-gray-200",
    voice: "text-white bg-voice-light focus:ring-voice hover:bg-voice-dark",
    black: "text-white bg-gray-900 hover:bg-gray-700 focus:ring-gray-900 active:bg-gray-90",
};

export const sizes = {
    sm: "px-2.5 py-1.5 text-xs",
    md: "px-4 py-2.5 text-base",
    lg: "px-6 py-3 text-base",
};
