import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Button from "../buttons/button";
import { router, usePage } from "@inertiajs/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Description, Field, Label, Switch } from '@headlessui/react'

export default function CookieNotice() {

    const { cookiesAccepted } = usePage().props;
    const [isCookieNoticeVisible, setIsCookieNoticeVisible] = useState(!cookiesAccepted);
    const [isPreferencesVisible, setIsPreferencesVisible] = useState(false);
    const [enabled, setEnabled] = useState({all: false, smartLook: false});

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const cookiePref = searchParams.get('cookiePreferences');

        if (cookiePref === 'true') {
            setIsPreferencesVisible(true);
        } else {
            setIsPreferencesVisible(false);
        }
    }, []);

    const handleCookiePreferences = (isAcceptAll = false) => {

        if (isAcceptAll) {
            enabled.smartLook = true;
        }

        const policyValue = enabled.smartLook ? 11000 : 10000;

        router.post(
            route("home.acceptCookies"),
            { policy: policyValue },
            {
                onSuccess: () => {
                    const searchParams = new URLSearchParams(window.location.search);
                    searchParams.delete('cookiePreferences');
                    const newUrl = `${window.location.pathname}${searchParams.toString()}`;
                    window.history.replaceState(null, '', newUrl);
                }
            }
        );

        setIsCookieNoticeVisible(false);
        setIsPreferencesVisible(false);
    };

    const openPreferences = () => {
        setIsCookieNoticeVisible(false);
        setIsPreferencesVisible(true);
    };

    const closePreferences = () => {
        setIsPreferencesVisible(false);
        setIsCookieNoticeVisible(true);
    };

    const toggleAll = () => {
        const allEnabled = Object.values(enabled).every((value) => value);
        setEnabled({
          all: !allEnabled,
          smartLook: !allEnabled,
        });
      };

    if (!isCookieNoticeVisible && !isPreferencesVisible) return null;

    return (
        <>
        {isCookieNoticeVisible && (
            <div className="fixed inset-x-0 bottom-8 z-[999] bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-5 sm:max-w-2xl mx-auto rounded-lg">
                <div className="flex items-center">
                    <h2 className="text-2xl font-bold text-gray-900">
                        <FormattedMessage id="Can we use optional cookies?" />
                    </h2>
                </div>
                <div className="pt-2 text-gray-600">
                    <FormattedMessage id="We use cookies and similar technologies to deliver, maintain, improve our services and for security purposes. We won’t turn them on until you accept." />{" "}
                    <a
                        href={route("privacy-policy")}
                        tabIndex={2}
                        className="underline underline-offset-4"
                    >
                        <FormattedMessage id="Learn more in our cookie policy." />
                    </a>
                </div>
                <div className="sm:flex gap-4 sm:flex-row-reverse mt-4">
                    <Button
                        tabIndex={1}
                        color="black"
                        onClick={() => handleCookiePreferences(true)}
                    >
                        <FormattedMessage id="Accept all" />
                    </Button>
                    <Button
                        tabIndex={1}
                        color="white"
                        onClick={openPreferences}
                    >
                        <FormattedMessage id="Manage preferences" />
                    </Button>
                </div>
            </div>
        )}

        {isPreferencesVisible && (
            <div className="flex flex-col gap-5 fixed inset-x-0 bottom-8 z-[999] bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-5 sm:max-w-2xl mx-auto rounded-lg max-h-[calc(100vh-5rem)] overflow-y-auto">
                <section>
                    <div className="flex justify-between">
                        <h2 className="text-2xl font-bold text-gray-900">
                            <FormattedMessage id="Your Privacy Matters" />
                        </h2>
                        <button
                            onClick={closePreferences}
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                        >
                            <span className="sr-only">
                                <FormattedMessage id="Close" />
                            </span>
                            <XMarkIcon className="size-6" />
                        </button>
                    </div>
                    <div className="pt-2 text-gray-900">
                        <FormattedMessage id="A cookie is a small piece of data (text file) that a website - when visited by a user - asks your browser to store on your device to remember information about you, such as your language preference or login information. Those cookies are set by us and called first-party cookies. We also use third-party cookies - which are cookies from a domain different than the domain of the website you are visiting - to help us improve our service to you. More specifically, we use cookies and other tracking technologies for the following purposes:" />
                    </div>
                </section>

                <section>
                    <div className="flex justify-between">
                        <h2 className="text-2xl font-bold text-gray-900">
                            <FormattedMessage id="Strictly Necessary Cookies" />
                        </h2>
                        <p className="text-green-800">Always Active</p>
                    </div>
                    <div className="pt-2 text-gray-900">
                        <FormattedMessage id="These cookies are necessary for our website to function and cannot be switched off. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information." />
                    </div>

                    <Field className="pt-4">

                        <Label as="span" passive className="font-bold text-gray-900">
                            <FormattedMessage id="Can't be turned off" />
                        </Label>

                        <div className="flex justify-between items-center mt-2 py-4 border-y-2">
                            <Description as="span" className="text-sm text-gray-900">
                                <FormattedMessage id="Security" />
                            </Description>

                            <Switch
                                checked={true}
                                onChange={() => {}}
                                disabled
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:ring-offset-2"
                                >
                                <span
                                    aria-hidden="true"
                                    className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                />
                                </Switch>
                        </div>

                        <div className="flex justify-between items-center py-4 border-b-2">
                            <Description as="span" className="text-sm text-gray-900">
                                <FormattedMessage id="Voice Platform" />
                            </Description>

                            <Switch
                                checked={true}
                                onChange={() => {}}
                                disabled
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:ring-offset-2"
                            >
                                <span
                                aria-hidden="true"
                                className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                />
                            </Switch>
                        </div>

                    </Field>
                </section>

                <section>
                    <h2 className="text-2xl font-bold text-gray-900">
                        <FormattedMessage id="Analytics Cookies" />

                    </h2>
                    <div className="pt-2 text-gray-600">
                        <FormattedMessage id="These cookies help us measure and improve the performance of our site by understanding how visitors use and interact with platform content and features." />
                    </div>

                    <Field className="pt-4">

                        <div className="flex justify-between items-center mt-2 py-4 border-y-2">
                            <Description as="span" className="text-sm text-gray-900 font-bold">
                                <FormattedMessage id="Turn all on/off" />
                            </Description>

                            <Switch
                                checked={enabled.all}
                                onChange={toggleAll}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-300 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 data-[checked]:bg-gray-900"
                                aria-label="Turn all on or off"
                            >
                                <span
                                aria-hidden="true"
                                className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                />
                            </Switch>
                        </div>

                        <div className="flex justify-between items-center py-4 border-b-2">
                            <Description as="span" className="text-sm text-gray-900">
                                <FormattedMessage id="Smartlook" />
                            </Description>

                            <Switch
                                checked={enabled.smartLook}
                                onChange={toggleAll}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-300 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 data-[checked]:bg-gray-900"
                                aria-label="Smartlook"
                            >
                                <span
                                aria-hidden="true"
                                className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                />
                            </Switch>
                        </div>

                    </Field>

                </section>

                <div className="sm:flex gap-4 sm:flex-row-reverse mt-4">
                <Button tabIndex={1} color="black" onClick={() => handleCookiePreferences(true)}>
                    <FormattedMessage id="Accept all cookies" />
                </Button>
                <Button tabIndex={1} color="white" onClick={() => handleCookiePreferences(false)}>
                    <FormattedMessage id="Save choices" />
                </Button>
                </div>
            </div>
        )}
        </>
    );
}
