import { useState } from "react";
import { FormattedMessage } from "react-intl";

interface RealmRedirectBannerProps {
    text: string;
    link: string;
}

export default function RealmRedirectBanner({
    text,
    link,
}: RealmRedirectBannerProps) {
    const [closed, setClosed] = useState(false);
    if (closed) return null;
    return (
        <div className="relative h-18 sm:h-20 w-full">
            <div
                className={`bg-black w-full h-18 sm:h-20 text-sm sm:text-base text-white inline-flex items-center justify-center`}
                aria-labelledby="realm-redirect-banner"
            >
                <p className="md:justify-start md:space-x-10 font-light max-w-7xl px-6 md:px-6 lg:px-12 py-1 inline-flex items-center justify-center">
                    {text}
                </p>

                <p className="max-w-7xl px-6 md:px-6 lg:px-10 py-1 inline-flex items-center justify-center">
                    <a
                        href={link}
                        className="h-10 px-3 inline-flex items-center justify-center whitespace-nowrap
                        rounded-lg border border-transparent transition-colors duration-150 text-base font-medium
                        shadow-sm focus:shadow-outline border-b-1 border-gray-900 text-gray-900 bg-gray-50 hover:bg-gray-200"
                    >
                        <FormattedMessage id="Continue" />
                    </a>
                    <button
                        type="button"
                        onClick={() => {
                            setClosed(true);
                            sessionStorage.setItem("realmDismiss", "true");
                        }}
                        className="h-4 pl-8 py-1 inline-flex items-center justify-center text-gray-200 hover:text-rose-600"
                    >
                        <span className="sr-only">Close menu</span>
                        <svg
                            className="size-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </p>
            </div>
        </div>
    );
}
